import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta:{
      title: 'Hello Curriculm'
    },
    component: () => import('@/views/IndexView.vue')
  },
  {
    path: '/curriculumHome',
    name: 'home',
    meta:{
      title: 'FaFu-Js 课表助手系统'
    },
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/curriculumLink',
    name: 'link',
    meta:{
      title: 'FaFu-Js 订阅'
    },
    component: () => import('@/views/LinkView.vue')
  },
  {
    path: '/curriculumHelp',
    name: 'help',
    meta:{
      title: 'FaFu-Js 帮助'
    },
    component: () => import('@/views/HelpView.vue')
  },
  {
    path: '/curriculumLog',
    name: 'log',
    meta:{
      title: 'FaFu-Js 系统更新日志'
    },
    component: () => import('@/views/LogView.vue')
  },
  {
    path: '/curriculumData',
    name: 'data',
    meta:{
      title: 'FaFu-Js 数据大屏'
    },
    component: () => import('@/views/DataView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
